import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 className="sprk-b-PageTitle sprk-b-TypeDisplayOne sprk-u-mbl sprk-u-Measure">
  Content Style
    </h1>
    <p>{`UX Writers, designers and developers can use this `}<strong parentName="p">{`Content Style Guide`}</strong>{` as an instruction to writing style, usage, text for copy, documentation, reference information, and copy for all Rocket Products digital experiences.`}</p>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Main Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Use headings to label sections in a page according to their importance.`}</li>
      <li parentName="ul">{`As a general rule, use `}<strong parentName="li">{`start case`}</strong>{` for headers.`}</li>
      <li parentName="ul">{`Use `}<strong parentName="li">{`sentence case`}</strong>{` for body copy, email subject lines and pre-headers.`}</li>
      <li parentName="ul">{`Use `}<strong parentName="li">{`double quotes`}</strong>{` when quoting someone’s words.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`Table Of Contents`}</h3>
    <ul>
  <li>
    <a href="#capitalization">Capitalization</a>
  </li>
  <ul className="sprk-b-List sprk-b-List--indented sprk-u-Measure sprk-u-mvs">
    <li>
      <a href="#start-case">Start Case</a>
    </li>
    <li>
      <a href="#sentence-case">Sentence Case</a>
    </li>
  </ul>
  <li>
    <a href="#quotation-marks">Quotation Marks</a>
  </li>
  <li>
    <a href="#accessibility">Accessibility</a>
  </li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Capitalization`}</h2>
    <p>{`Use for visual presentation of pieces of text to make it easy for users to read.`}</p>
    <blockquote>
      <p parentName="blockquote">{`As a general rule, use `}<strong parentName="p">{`start case`}</strong>{` for `}<strong parentName="p">{`headers`}</strong>{`, use either `}<strong parentName="p">{`start case`}</strong>{` or `}<strong parentName="p">{`sentence case`}</strong>{` for `}<strong parentName="p">{`dropdown options`}</strong>{` and `}<strong parentName="p">{`labels`}</strong>{`, depending on the context.`}</p>
    </blockquote>
    <h3>{`Start Case`}</h3>
    <p>{`Capitalize the first letter of each word, regardless of length or part of speech. When using start case, avoid using end punctuation other than question marks.`}</p>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Usage</h3>
    <ul>
      <li parentName="ul">{`Headers that are descriptive or functional`}</li>
      <li parentName="ul">{`All subheads regardless of whether the primary header is title or sentence case`}</li>
      <li parentName="ul">{`All buttons labels (Primary, Secondary and Tertiary Buttons)`}</li>
      <li parentName="ul">{`Menu items`}</li>
      <li parentName="ul">{`Chart and table headers`}</li>
      <li parentName="ul">{`Referring to the name of a page`}</li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Examples</h3>
    <ul>
      <li parentName="ul">{`Homeowners Insurance Info`}</li>
      <li parentName="ul">{`How To Buy A Home`}</li>
      <li parentName="ul">{`Lock Your Rate And Save`}</li>
      <li parentName="ul">{`Check Your To Do List`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h3>{`Sentence Case`}</h3>
    <p>{`Sentence case capitalizes the first word of the sentence as well as proper nouns.`}</p>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Usage</h3>
    <ul>
      <li parentName="ul">{`Emotional and Impactful headers`}</li>
      <li parentName="ul">{`Special instances, where there may be two primary headers on a page`}</li>
      <li parentName="ul">{`Body copy`}</li>
      <li parentName="ul">{`Email subject lines and pre-headers`}</li>
      <li parentName="ul">{`Use end punctuation when the list item is a complete sentence`}</li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Examples</h3>
    <ul>
      <li parentName="ul">{`We’re here to help!`}</li>
      <li parentName="ul">{`If you want the lowest payment possible, you might want to look at a 30-year fixed loan.`}</li>
      <li parentName="ul">{`Lock your rate and save.`}</li>
      <li parentName="ul">{`Interest rates are on the rise.`}</li>
      <li parentName="ul">{`Welcome to Rocket Mortgage.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Quotation Marks`}</h2>
    <p>{`Use opening and closing quotes to show the start and endpoint of quoted content.`}</p>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Usage</h3>
    <ul>
      <li parentName="ul">{`Use `}<strong parentName="li">{`double`}</strong>{` quotes for quoting someone’s words.`}</li>
      <li parentName="ul">{`When quotation marks are needed in headlines, subheads and email subject lines, use `}<strong parentName="li">{`single`}</strong>{` quotation marks.`}</li>
      <li parentName="ul">{`Don’t use quotation marks when citing the full name of an online article within an interface.`}</li>
    </ul>
    <h3 className="sprk-b-TypeDisplayFour sprk-u-mbm sprk-u-Measure">Examples</h3>
    <p><strong parentName="p">{`Do's`}</strong></p>
    <ul>
      <li parentName="ul">{`"Rocket Mortgage®, Built for Zero and communities like mine have ended veteran homelessness," says John Meier, U.S. Marine Corps veteran and Built for Zero leader.`}</li>
      <li parentName="ul">{`Money Magazine says, 'Rocket Mortgage's online application is simple.'`}</li>
    </ul>
    <p><strong parentName="p">{`Don'ts`}</strong></p>
    <ul>
      <li parentName="ul">{`Sort by “Submitted” to see documents you’ve sent us.`}</li>
      <li parentName="ul">{`You can get more information from our “Home Buying Checklist For First-Time Home Buyers.”`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`For more information on `}<strong parentName="p">{`Content Style Guidelines`}</strong>{`, refer to the `}<a parentName="p" {...{
          "href": "https://www.w3.org/Guide/manual-of-style/"
        }}>{`W3C Style Guide (Web Content Manual of Style)`}</a>{`.`}</p>
    </blockquote>
    <SprkDivider element="span" additionalClasses="sprk-u-mvn" mdxType="SprkDivider" />
    <h2>{`Accessibility`}</h2>
    <p>{`Our guides are not all-inclusive. For more information, refer to the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`Web Content Accessibility Guidelines (WCAG 2.1, Level AA)`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      